// Form Names
export const aboutYouFormIdentifier = 'aboutYou';
export const yourHomeFormIdentifier = 'yourHome';
export const yourHomeCoverFormIdentifier = 'yourHomeCover';
export const yourCarFormIdentifier = 'yourCar';
export const yourCarCoverFormIdentifier = 'yourCarCover';
export const yourTravelFormIdentifier = 'yourTravel';
export const yourTravelCoverFormIdentifier = 'yourTravelCover';
export const yourDeviceFormIdentifier = 'yourDevice';
export const yourDeviceCoverFormIdentifier = 'yourDeviceCover';
export const yourVanFormIdentifier = 'yourVan';
export const yourVanCoverFormIdentifier = 'yourVanCover';
export const dutyOfDisclosureURL =
  'https://www.arachas.ie/media/s54fmpnq/duty-of-disclosure.pdf';
export const termsOfBusinessURL =
  'https://www.arachas.ie/media/01phjeck/tob_2023v1_march.pdf';
export const scheduleOfFees =
  '//www.arachas.ie/media/3julsmgd/schedule-of-fees-june-2022.pdf';
export const termsOfUse =
  'https://www.arachas.ie/media/vg0bz020/terms-of-use.pdf';
export const remuneration = '//www.arachas.ie/compliance/remuneration/';
export const dataPrivacy = '//www.arachas.ie/compliance/privacy-policy/';
