/**
 * Copyright 2015-present Singlepoint. All Rights Reserved.
 *
 * @flow
 */

import './LegalText.scss';

import type { StatelessFunctionalComponent } from 'react';
import React from 'react';

import {
  CAR_INSURANCE_TYPE,
  COMMON_INSURANCE_TYPE,
  DEVICE_INSURANCE_TYPE,
  HOME_INSURANCE_TYPE,
  TRAVEL_INSURANCE_TYPE,
  VAN_INSURANCE_TYPE,
} from '../../constants';
import {
  commonLegalTextContent,
  legalTextDeviceContent,
  legalTextHomeContent,
  legalTextHomeContentTwo,
  legalTextMotorContent,
  legalTextTravelContent,
  legalTextVanContent,
} from './legalTextContent';

interface Props {
  insuranceType: string;
}

const className = 'c-LegalText';

const legalText = {
  [HOME_INSURANCE_TYPE]: legalTextHomeContent,
  [CAR_INSURANCE_TYPE]: legalTextMotorContent,
  [TRAVEL_INSURANCE_TYPE]: legalTextTravelContent,
  [DEVICE_INSURANCE_TYPE]: legalTextDeviceContent,
  [VAN_INSURANCE_TYPE]: legalTextVanContent,
  [COMMON_INSURANCE_TYPE]: commonLegalTextContent,
};
const commonLegalTextArr = [
  HOME_INSURANCE_TYPE,
  VAN_INSURANCE_TYPE,
  CAR_INSURANCE_TYPE,
];

const LegalText: StatelessFunctionalComponent<{ insuranceType: string }> = (
  props: Props
) =>
  legalText[props.insuranceType] ? (
    <div>
      <p className={className}>
        {commonLegalTextArr.includes(props.insuranceType)
          ? legalText[COMMON_INSURANCE_TYPE]
          : legalText[props.insuranceType]}
      </p>
      <p className={className}>
        {!commonLegalTextArr.includes(props.insuranceType)
          ? legalTextHomeContentTwo
          : null}
      </p>
    </div>
  ) : (
    ''
  );

export default LegalText;
