import {
  ALLIANZ_SCHEMA_NAME,
  AVIVA_SCHEMA_NAME,
  AXA_SCHEMA_NAME,
  LIBERTY_SCHEMA_NAME,
  ZURICH_SCHEMA_NAME,
} from '../../../constants';
import { isHoliday, isLandlord, isLiving } from '../../../constants/home';
import {
  DEVICE_INSURANCE_TYPE,
  HOME_INSURANCE_TYPE,
  TRAVEL_INSURANCE_TYPE,
  VAN_INSURANCE_TYPE,
} from '../../../constants/insuranceTypeConstants';

export const homeLinks = [
  {
    label: 'Terms of use',
    url: '//www.arachas.ie/media/vg0bz020/terms-of-use.pdf',
  },
  {
    label: 'Terms of Business',
    url: 'https://www.arachas.ie/media/01phjeck/tob_2023v1_march.pdf',
  },
  {
    label: 'Privacy Policy',
    url: '//www.arachas.ie/compliance/privacy-policy',
  },
  {
    label: 'Schedule of Fees and Charges',
    url: '//www.arachas.ie/media/3julsmgd/schedule-of-fees-june-2022.pdf',
  },
  {
    label: 'Remuneration Details',
    url: '//www.arachas.ie/compliance/remuneration',
  },
  {
    label: 'Arachas Home Insurance Assumptions',
    url: '//www.arachas.ie/media/csidyuv0/home-insurance-assumptions.pdf',
  },
  {
    label: 'Home No Claims Bonus Scale',
    url: '//www.arachas.ie/media/ojbbxfkf/home-no-claims-bonus-scale.pdf',
  },
  {
    label: 'Duty of Disclosure',
    url: '//www.arachas.ie/media/s54fmpnq/duty-of-disclosure.pdf',
  },
];

export const travelLinks = [
  {
    label: 'Terms of Business',
    url: 'https://www.arachas.ie/tob',
  },
  {
    label: 'Terms of use',
    url: '//www.arachas.ie/media/wp1jm3m1/terms-of-use.pdf',
  },
  {
    label: 'Duty of Disclosure',
    url: '//www.arachas.ie/media/vqnj4ynu/duty-of-disclosure.pdf',
  },
  {
    label: 'Privacy Policy',
    url: '//www.arachas.ie/compliance/privacy-policy/',
  },
  {
    label: 'Schedule of Fees and Charges',
    url: '//www.arachas.ie/schedule-of-fees.pdf',
  },
  {
    label: 'Remuneration Details',
    url: '//www.arachas.ie/compliance/remuneration/',
  },
];

export const deviceLinks = [
  {
    label: 'Policy Declaration',
    url: '//www.arachas.ie/device-policy-declarations ',
  },
  {
    label: 'Policy Documents',
    url: '//www.arachas.ie/arachas-zurich-device-policy-document',
  },
  {
    label: 'CICA Update',
    url: '//www.arachas.ie/media/cs1nw3oc/cica_notice_arac.pdf',
  },
  {
    label: 'IPID',
    url: '//www.arachas.ie/arachas-zurich-device-IPID-document',
  },
];

export const allianzLinksCar = [
  {
    label: 'Policy Document',
    url: '//www.arachas.ie/allianz-motor-insurance-policy-document',
  },
  {
    label: 'Allianz IPID document',
    url: '//www.arachas.ie/allianz-insurance-product-information-ipid',
  },
];

export const libertyLinksCar = [
  {
    label: 'Policy Document',
    url: '//www.arachas.ie/media/zvaa4qoa/liberty-motor-insurance-policy.pdf',
  },
  {
    label: 'Liberty IPID document',
    url: '//www.arachas.ie/media/uxnnnmai/libery-motor-ipid.pdf',
  },
];

export const zurichLinksCar = [
  {
    label: 'Private Car Policy Document',
    url:
      '//www.arachas.ie/media/3lijuwky/zurich-private-car-insurance-policy-document.pdf',
  },
  {
    label: 'Private Car Insurance Product Information Document (IPID)',
    url: '//www.arachas.ie/media/e54basub/zurich-private-car-ipid.pdf',
  },
];

export const avivaLinksCar = [
  {
    label: 'Policy Booklet',
    url: '//www.arachas.ie/aviva-MotorChoice-Policy-Document',
  },
  {
    label: 'Aviva IPID document',
    url: '//www.arachas.ie/aviva-MotorChoice-Policy-IPID',
  },
];

export const axaLinksCar = [
  {
    label: 'Policy Booklet',
    url: '//www.arachas.ie/axa-car-insurance-policy',
  },
  {
    label: 'Axa IPID document',
    url: '//www.arachas.ie/axa-car-insurance-ipid',
  },
];

export const avivaLinksHome = [
  {
    label: 'Policy Booklet',
    url: '//www.arachas.ie/aviva-MotorChoice-Policy-Document',
  },
  {
    label: 'Aviva IPID document',
    url: '//www.arachas.ie/aviva-MotorChoice-Policy-IPID',
  },
];

export const genericProviderLinksCar = [
  {
    label: 'Terms of use',
    url: '//www.arachas.ie/media/wp1jm3m1/terms-of-use.pdf',
  },
  {
    label: 'Terms of Buisness',
    url: '//www.arachas.ie/media/01phjeck/tob_2023v1_march.pdf',
  },
  {
    label: 'Privacy policy',
    url: '//www.arachas.ie/compliance/privacy-policy/',
  },
  {
    label: 'Schedule of fees and charges',
    url: '//www.arachas.ie/media/3julsmgd/schedule-of-fees-june-2022.pdf',
  },
  {
    label: 'Remuneration Details',
    url: '//www.arachas.ie/compliance/remuneration/',
  },
  {
    label: 'Car No Claims Bonus Scale',
    url: '//www.arachas.ie/media/nbkg5l2e/car-no-claims-bonus-scale.pdf',
  },
  {
    label: 'Duty of Disclosure',
    url: '//www.arachas.ie/media/s54fmpnq/duty-of-disclosure.pdf',
  },
  {
    label: 'Assumptions',
    url: '//www.arachas.ie/media/p5rnxgin/car-insurance-assumptions.pdf',
  },
];

export const vanLinks = [
  {
    label: 'Terms of use',
    url: '//www.arachas.ie/media/wp1jm3m1/terms-of-use.pdf',
  },
  {
    label: 'Terms of Business',
    url: '//www.arachas.ie/media/cphenioo/terms-of-business_2020v4.pdf',
  },
  {
    label: 'Privacy policy',
    url: '//www.arachas.ie/compliance/privacy-policy/',
  },
  {
    label: 'Schedule of fees and charges',
    url:
      '//www.arachas.ie/media/f0lpcca1/arachas-schedule-of-fees-15-sept-2020.pdf',
  },
  {
    label: 'Remuneration Details',
    url: '//www.arachas.ie/compliance/remuneration/',
  },
  {
    label: 'Van No Claims Bonus Scale',
    url: '//www.arachas.ie/media/qywlglnz/van-no-claims-bonus-scales.pdf',
  },
  {
    label: 'Duty of Disclosure',
    url: '//www.arachas.ie/media/ga3n3tzk/duty-of-disclosure.pdf',
  },
  {
    label: 'Assumptions',
    url: '//www.arachas.ie/arachas-van-insurance-assumptions',
  },
  {
    label: 'Policy Document',
    url: '//www.arachas.ie/axa-vanfirst-insurance-policy-document',
  },
  {
    label: 'Axa IPID Document',
    url: '//www.arachas.ie/axa-vanfirst-ipid',
  },
];
const zurichLiving = [
  {
    label: 'Zurich Home Insurance IPID document',
    url:
      '//www.arachas.ie/media/r4gnmkim/zurich-arachas-home-ipid-document.pdf',
  },
  {
    label: 'Zurich Guide to Home Insurance Benefits',
    url: '//www.arachas.ie/media/e41llqei/guide-to-home-insurance-benefits.pdf',
  },
  {
    label: 'Zurich Home Insurance Policy Document',
    url: '//www.arachas.ie/media/cbwfez11/home-insurance-policy-document.pdf',
  },
];

const zurichHoliday = [
  {
    label: 'Zurich Home Insurance IPID document',
    url:
      '//www.arachas.ie/media/r4gnmkim/zurich-arachas-home-ipid-document.pdf',
  },
  {
    label: 'Zurich Guide to Home Insurance Benefits',
    url: '//www.arachas.ie/media/e41llqei/guide-to-home-insurance-benefits.pdf',
  },
  {
    label: 'Zurich Home Insurance Policy Document',
    url:
      '//www.arachas.ie/media/sirfn1c3/home-insurance-policy-document-landlord-and-holiday-home.pdf',
  },
];

const zurichLandlord = [
  {
    label: 'Zurich Home Insurance IPID document',
    url:
      '//www.arachas.ie/media/r4gnmkim/zurich-arachas-home-ipid-document.pdf',
  },
  {
    label: 'Zurich Guide to Landlord Insurance Benefits',
    url:
      '//www.arachas.ie/media/hfup3iv2/guide-to-landlord-insurance-benefits.pdf',
  },
  {
    label: 'Zurich Home Insurance Policy Document - Landlord & Holiday Home',
    url:
      '//www.arachas.ie/media/sirfn1c3/home-insurance-policy-document-landlord-and-holiday-home.pdf',
  },
];

const libertyLiving = [
  {
    label: 'Liberty Home Insurance IPID document',
    url: '//www.arachas.ie/media/kxohvodb/liberty-ipid.pdf',
  },
  {
    label: 'Liberty Home Insurance Policy Document',
    url: '//www.arachas.ie/media/3mgjcopb/liberty-home-insurance-policy.pdf',
  },
];

const libertyLandlordHoliday = [
  {
    label: 'Liberty Home Insurance IPID document',
    url: '//www.arachas.ie/media/kxohvodb/liberty-ipid.pdf',
  },
  {
    label: 'Liberty Home Insurance Policy Document',
    url: '//www.arachas.ie/media/3mgjcopb/liberty-home-insurance-policy.pdf',
  },
];

const allianzLiving = [
  {
    label: 'Allianz Home Insurance IPID document',
    url: '//www.arachas.ie/allianz-home-insurance-product-IPID',
  },
  {
    label: 'Allianz Home Insurance Policy Document',
    url: '//www.arachas.ie/allianz-home-policy-book',
  },
];

const allianzLandlordHoliday = [
  {
    label: 'Allianz Home Insurance IPID document',
    url: '//www.arachas.ie/allianz-home-insurance-product-IPID',
  },
  {
    label: 'Allianz Home Insurance Policy Document',
    url: '//www.arachas.ie/allianz-home-policy-book',
  },
];

const avivaLivingLandlord = [
  {
    label: 'Aviva Home Insurance IPID document',
    url: '//www.arachas.ie/aviva-homechoice-insurance-product-IPID',
  },
  {
    label: 'Aviva Guide to Home Insurance benefits',
    url: '//www.arachas.ie/aviva-home-benefits-document',
  },
  {
    label: 'Aviva Home Insurance Policy Document',
    url: '//www.arachas.ie/aviva-homechoice-policy-booklet',
  },
];

const axaLiving = [
  {
    label: 'Axa Home Insurance Policy Document',
    url: '//www.arachas.ie/AXA-home-policy-document',
  },
];

const axaHoliday = [
  {
    label: 'Axa Home Insurance IPID document',
    url: '//www.arachas.ie/AXA-holiday-home-IPID',
  },
  {
    label: 'Axa Home Insurance Policy Document',
    url: '//www.arachas.ie/AXA-holiday-home-policy-document',
  },
];

const axaLandlord = [
  {
    label: 'Axa Home Insurance IPID document',
    url: '//www.arachas.ie/media/umldcne4/axa-home-landlord-ipid.pdf',
  },
  {
    label: 'Axa Home Insurance Policy Document',
    url: '//www.arachas.ie/AXA-landlord-policy-document',
  },
];

export const staticPolicyLinksPage = {
  [HOME_INSURANCE_TYPE]: homeLinks,
  [TRAVEL_INSURANCE_TYPE]: travelLinks,
  [DEVICE_INSURANCE_TYPE]: deviceLinks,
  [VAN_INSURANCE_TYPE]: vanLinks,
};

export const insurerLinksCar = {
  [ALLIANZ_SCHEMA_NAME]: allianzLinksCar,
  [LIBERTY_SCHEMA_NAME]: libertyLinksCar,
  [ZURICH_SCHEMA_NAME]: zurichLinksCar,
  [AVIVA_SCHEMA_NAME]: avivaLinksCar,
  [AXA_SCHEMA_NAME]: axaLinksCar,
};

// eslint-disable-next-line complexity
export const getLinksPage = (coverType, insurerName) => {
  if (insurerName === ALLIANZ_SCHEMA_NAME) {
    if (isLiving(coverType)) return allianzLiving;
    if (isHoliday(coverType)) return allianzLandlordHoliday;
    if (isLandlord(coverType)) return allianzLandlordHoliday;
  }

  if (insurerName === LIBERTY_SCHEMA_NAME) {
    if (isLiving(coverType)) return libertyLiving;
    if (isHoliday(coverType)) return libertyLandlordHoliday;
    if (isLandlord(coverType)) return libertyLandlordHoliday;
  }

  if (insurerName === ALLIANZ_SCHEMA_NAME) {
    if (isLiving(coverType)) return allianzLiving;
    if (isHoliday(coverType)) return allianzLandlordHoliday;
    if (isLandlord(coverType)) return allianzLandlordHoliday;
  }

  if (insurerName === ZURICH_SCHEMA_NAME) {
    if (isLiving(coverType)) return zurichLiving;
    if (isHoliday(coverType)) return zurichHoliday;
    if (isLandlord(coverType)) return zurichLandlord;
  }

  if (insurerName === AVIVA_SCHEMA_NAME) {
    if (isLiving(coverType) || isLandlord(coverType))
      return avivaLivingLandlord;
  }
  if (insurerName === AXA_SCHEMA_NAME) {
    if (isLiving(coverType)) return axaLiving;
    if (isHoliday(coverType)) return axaHoliday;
    if (isLandlord(coverType)) return axaLandlord;
  }
};
