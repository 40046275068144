import './DirectDebit.scss';

import { DesktopCard, WideDivider } from '@arachas/core/lib';
import PropTypes from 'prop-types';
import React from 'react';

import { dividerColor } from '../../../constants/colors';
import { commonFormStylesIdentifier } from '../../../constants/styleConstants';
import PaymentFrequency from '../CarPayment/PaymentFrequency';

const className = 'c-GoCardlessDirectDebit';

const DirectDebit = props => {
  const {
    paymentOptions,
    selectedQuoteData
  } = props;


  return (
    <div>
      <DesktopCard>
        <div className={`${className}__contentContainer`}>
          <div>
            <PaymentFrequency {...props} paymentOptions={paymentOptions} />

            <div className={`${commonFormStylesIdentifier}__dividerContainer`}>
              <WideDivider height={1} color={dividerColor} />
            </div>
            <div className={`${className}__directDebitHeading`}>
              Arachas are happy to offer alternative payment plans on our
              policies. If you would like to discuss the payment options open to
              you, please contact our team on <a href="tel:01 486 9775">01 486 9775</a> quoting the reference
              number <span className={`${className}__quoteReference`}>{selectedQuoteData.quoteReference}</span>.
            </div>
          </div>
        </div>
      </DesktopCard>
    </div>

    // Commenting this code for future use of GoCardlessDirectDebit 
    // <div>
    //   <DesktopCard>
    //     <div className={`${className}__contentContainer`}>
    //       <div>
    //         <PaymentFrequency {...props} paymentOptions={paymentOptions} />

    //         <div className={`${commonFormStylesIdentifier}__dividerContainer`}>
    //           <WideDivider height={1} color={dividerColor} />
    //         </div>
    //         <div className={`${className}__directDebitHeading`}>Set up a Direct Debit mandate with Arachas</div>
    //         <PaymentDetails {...selectedQuoteData} />

    //         <div className={`${commonFormStylesIdentifier}__dividerContainer`}>
    //           <WideDivider height={1} color={dividerColor} />
    //         </div>

    //         <GoCardless {...props} />

    //         <GoCardlessLegal values={values} setFieldValue={setFieldValue} />

    //       </div>
    //     </div>
    //   </DesktopCard>

    //   <div>
    //     <div className={`${className}__legalNotice`}>
    //       <div className={`${className}__dividerContainer`}>
    //         <WideDivider height={1} color={dividerColor} />
    //       </div>
    //       <div className={`${className}--margin`}>
    //         If you have any queries, please visit the Arachas website <a
    //           href='https://www.arachas.ie/'
    //           className={`${className}__link`}
    //           target='_blank'
    //           rel="noopener noreferrer">here</a>. Arachas Corporate Brokers Limited. Arachas
    //         Corporate Brokers Limited trading as Arachas, Capital Insurance Markets, Capital IM, Covercentre is
    //         regulated
    //         by
    //         the Central Bank of Ireland. Registered in Ireland No. 379157.
    //       </div>
    //     </div>
    //   </div>
    // </div>
  );
};

DirectDebit.propTypes = {
  values: PropTypes.object,
  setFieldValue: PropTypes.func,
  selectedQuoteData: PropTypes.object,
  paymentOptions: PropTypes.array,
  insuranceType: PropTypes.string,
};

export default DirectDebit;
