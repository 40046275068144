import * as yup from 'yup';

import goCardlessYupSchema from '../CarPayment/GoCardless/goCardlesYupSchema';
import goCardlessLegalYupSchema from '../CarPayment/GoCardlessLegal/goCardlesLegalYupSchema';
import { PAYMENT_FREQUENCY } from '../values';

export default yup.object().shape({
  paymentFrequency: yup.string()
    .oneOf([PAYMENT_FREQUENCY.TOTAL, PAYMENT_FREQUENCY.MONTHLY])
    .required('Please enter a valid payment frequency'),
  cardIsValid: yup.boolean().when('paymentFrequency', {
    is: PAYMENT_FREQUENCY.TOTAL,
    then: yup.boolean().oneOf([true]).required()
  }),
  directDebitLegalText: yup
    .boolean()
    .when('paymentFrequency', {
      is: PAYMENT_FREQUENCY.DIRECT_DEBIT,
      then: yup.boolean().required()
        .oneOf([true], 'You must accept the Terms and Conditions')
    }),
  nameOnCard: yup
    .string()
    .when('paymentFrequency', {
      is: PAYMENT_FREQUENCY.TOTAL,
      then: yup
        .string()
        .matches(/^[a-z ]+$/i, 'Only letters are allowed')
        .min(2, "Please enter more than 2 characters")
        .max(50, "Only a maximum of 50 characters")
        .required('Please enter name on card.')
    }),
  ...goCardlessYupSchema,
  ...goCardlessLegalYupSchema,
});
