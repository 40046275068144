import * as yup from 'yup';

import { PAYMENT_FREQUENCY } from '../values';
import goCardlessYupSchema from './GoCardless/goCardlesYupSchema';
import goCardlessLegalYupSchema from './GoCardlessLegal/goCardlesLegalYupSchema';


export default yup.object().shape({
  paymentFrequency: yup.string()
    .oneOf([PAYMENT_FREQUENCY.TOTAL, PAYMENT_FREQUENCY.MONTHLY])
    .required('Please enter a valid payment frequency'),
  cardIsValid: yup.boolean().when('paymentFrequency', {
    is: PAYMENT_FREQUENCY.TOTAL,
    then: yup.boolean().oneOf([true]).required(),
  }),
  nameOnCard: yup
    .string()
    .when('paymentFrequency', {
      is: PAYMENT_FREQUENCY.TOTAL,
      then: yup
        .string()
        .matches(/^[a-z ]+$/i, 'Only letters are allowed')
        .min(2, 'Please enter more than 2 characters')
        .max(50, 'Only a maximum of 50 characters')
        .required('Please enter name on card.'),
    }),
  ...goCardlessYupSchema,
  ...goCardlessLegalYupSchema,
});
